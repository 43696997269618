import { useEffect } from 'react'
import usePagination from './usePagination';
import { indexProducts } from '../api/Products';

const useProducts = ({ headers, fibra_id, defaultPageSize = 10 }) => {
    const hookProducts = usePagination({ defaultPageSize });
    const { page, pageSize } = hookProducts.pagination;

    useEffect(() => {
        const getProducts = async () => {
            hookProducts.setLoading(true);
            const { data, totalItems } = await indexProducts({
                headers,
                page,
                pageSize,
                fibra_id
            });
            hookProducts.setTotalItems(totalItems)
            hookProducts.setData(data)
            hookProducts.setLoading(false);
        }
        getProducts();
    }, [page, pageSize, fibra_id]);

    return hookProducts;
}

export default useProducts
