import React, { useState } from 'react'
import { Row, Col, Card, Button, ListGroup, Badge, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import PageHeaderComp from '../Layouts/PageHeader'

const ShoppingCart = () => {
    const toast = useToast();
    const [t] = useTranslation('global');

    // Productos en el carrito
    const [cartItems, setCartItems] = useState([
        { id: 1, name: 'Nombre del producto 1', brand: 'MARCA', price: 150, quantity: 2 },
        { id: 2, name: 'Nombre del producto 2', brand: 'MARCA', price: 300, quantity: 1 },
        { id: 3, name: 'Nombre del producto 3', brand: 'MARCA', price: 250, quantity: 3 },
    ]);

    // Calcular el total del carrito
    const getTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    // Actualizar cantidad de un producto
    const updateQuantity = (id, quantity) => {
        const updatedCart = cartItems.map(item =>
            item.id === id ? { ...item, quantity: quantity } : item
        );
        setCartItems(updatedCart);
    };

    // Eliminar un producto del carrito
    const removeItem = (id) => {
        const updatedCart = cartItems.filter(item => item.id !== id);
        setCartItems(updatedCart);
        toast({
            title: t("cart.remove-success.title"),
            description: t("cart.remove-success.description"),
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Row className="mt-4">
            <Col md={8}>
                <PageHeaderComp
                    title='Carrito de compras'
                />
                <ListGroup variant="flush">
                    {cartItems.length > 0 ? cartItems.map(item => (
                        <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5>{item.name}</h5>
                                <p className="text-muted">{item.brand}</p>
                                <h6 className="text-primary">${item.price.toFixed(2)} c/u</h6>
                            </div>
                            <div className="d-flex align-items-center">
                                <Form.Control
                                    type="number"
                                    value={item.quantity}
                                    min="1"
                                    onChange={(e) => updateQuantity(item.id, parseInt(e.target.value) || 1)}
                                    style={{ width: '60px', marginRight: '10px' }}
                                />
                                <Button variant="danger" onClick={() => removeItem(item.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </div>
                            <div>
                                <Badge pill bg="info">Subtotal: ${(item.price * item.quantity).toFixed(2)}</Badge>
                            </div>
                        </ListGroup.Item>
                    )) : (
                        <ListGroup.Item>
                            <p>No hay productos en el carrito.</p>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Col>

            <Col md={4}>
                <Card>
                    <Card.Body>
                        <h4>Total: ${getTotalPrice()}</h4>
                        <Button variant="success" className="w-100 mt-3" disabled={cartItems.length === 0}>
                            Proceder al Pago
                        </Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ShoppingCart;
