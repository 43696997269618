import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, Button, Row, Col, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import MallImage from '../../images/mall.png';
import PageHeaderComp from '../Layouts/PageHeader'
import ButtonIcon from '../Layouts/Forms/ButtonIcon'
import { useHistory } from "react-router";

const ProductDetails = () => {
    const { productId } = useParams();
    const toast = useToast();
    const [t] = useTranslation('global');
    const prefix = 'product.details';
    const history = useHistory();

    // Simulación de obtención de datos de producto por ID
    const product = {
        id: productId,
        name: 'Nombre del producto',
        brand: 'MARCA',
        model: 'MODEL0012',
        description: 'Descripción detallada del producto, sus características, beneficios y usos.',
        price: 1312.23,
        stock: 10,
    };

    const handleAddToCart = () => {
        if (product.stock > 0) {
            toast({
                title: t(`${prefix}.add-to-cart-success.title`),
                description: t(`${prefix}.add-to-cart-success.description`),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: t(`${prefix}.out-of-stock.title`),
                description: t(`${prefix}.out-of-stock.description`),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <PageHeaderComp
                title={`${product.name}`}
                actions={[
                    <Row>
                        <Col>
                            <ButtonIcon icon={faShoppingCart} variant='outline-success' tooltip='Ver carrito de compras' onClick={() => history.push(`/shopping-cart`)} />
                        </Col>
                    </Row>
                ]}
            />
            <Row className="mt-4">
                <Col md={6} className="d-flex justify-content-center">
                    <Card.Img variant="top" src={MallImage} style={{ width: '80%', height: 'auto' }} />
                </Col>
                <Col md={6}>
                    <Card.Body>
                        <h2>{product.name}</h2>
                        <Badge pill bg={product.stock > 0 ? "success" : "danger"} className="mb-2">
                            {product.stock > 0 ? `${product.stock} disponibles` : "Agotado"}
                        </Badge>
                        <h4 className="text-muted">{product.brand}</h4>
                        <p><strong>Modelo:</strong> {product.model}</p>
                        <p><strong>Descripción:</strong> {product.description}</p>
                        <h3 className="text-primary">${product.price.toFixed(2)}</h3>
                        <Button variant="primary" onClick={handleAddToCart} disabled={product.stock === 0}>
                            <FontAwesomeIcon icon={faShoppingCart} className="me-2" />
                            {product.stock > 0 ? "Añadir al carrito" : "No disponible"}
                        </Button>
                    </Card.Body>
                </Col>
            </Row>
        </>
    );
};

export default ProductDetails;
