import React from 'react'
import PageHeaderComp from '../Layouts/PageHeader'
import ButtonIcon from '../Layouts/Forms/ButtonIcon'
import { faEdit, faPlus, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Badge, Button, Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToast } from '@chakra-ui/react'
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next'
import MallImage from '../../images/mall.png';
import FormIcon from '../Layouts/Forms/FormIcon'
import useProducts from '../../hooks/useProducts'
import { useSelector } from 'react-redux'
import PaginationComp from '../Layouts/Pagination'

const IndexShop = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const history = useHistory();
    const toast = useToast();
    const [t] = useTranslation('global');
    const prefix = 'malls.toasts';

    const hookProducts = useProducts({ headers, fibra_id, defaultPageSize: 20 });

    const redirectTo = (e, product) => {
        try {
            e.stopPropagation();
            history.push(`/shop/details/${product.id}`);
        } catch (error) {
            toast({
                title: t(`${prefix}.error-red.title`),
                description: t(`${prefix}.error-red.description`),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }
    return (
        <div>
            <PageHeaderComp
                title='Tienda'
                actions={[
                    <Row>
                        <Col>
                            <FormIcon name='search' size='sm' placeholder='Buscar producto' re_icon={<FontAwesomeIcon icon={faSearch} />} re_onClick={() => { }} />
                        </Col>
                        <Col>
                            <ButtonIcon icon={faShoppingCart} variant='outline-success' tooltip='Ver carrito de compras' onClick={() => history.push(`/shopping-cart`)} />
                        </Col>
                    </Row>
                ]}
            />

            <Row xs={1} md={2} lg={3} xl={4} xxl={5} className="g-4 mt-3" style={{ overflowY: 'auto', height: '70vh' }}>
                {hookProducts.data.map((product) => (
                    <Col key={product.id}>
                        <Card className="h-100 product-card" onClick={(e) => redirectTo(e, product)}>
                            <Card.Img variant="top" src={MallImage} />
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Card.Title className="mb-0">{product.brand}</Card.Title>
                                    <Badge pill bg={product.stock > 0 ? "success" : "danger"}>
                                        {product.stock > 0 ? `${product.stock} disponibles` : "Agotado"}
                                    </Badge>
                                </div>
                                <Card.Text>{product.name}</Card.Text>
                                <Card.Text>
                                    <strong>Modelo:</strong> {product.model}
                                </Card.Text>
                                <Card.Text className="text-primary h5">
                                    ${product.price.toFixed(2)}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-between">
                                <Button variant="primary" disabled={product.stock === 0}>
                                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" />
                                    {product.stock > 0 ? "Añadir al carrito" : "No disponible"}
                                </Button>
                                <Button variant="outline-secondary">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
            <PaginationComp
                current={hookProducts.pagination.page}
                onChange={hookProducts.changePage}
                totalItems={hookProducts.pagination.totalItems}
                defaultPageSize={hookProducts.pagination.pageSize}
            />
        </div>
    )
}

export default IndexShop