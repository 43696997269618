import { fetcherCancelToken } from "../libs/Petition";

const url = '/products';
const CREATED = 201;
const STATUS_OK = 200;

export const indexProducts = async ({ headers, fibra_id, page, pageSize }) => {

    const response = { data: [], totalItems: 0 };

    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { fibra_id, page, pageSize },
            headers
        });

        console.log("🚀 ~ file: Products.js:20 ~ indexProducts ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.totalItems = fetch.data.totalItems;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeProducts = async ({ headers, data }) => {
    let response = { res: false, data: {}, status: 500 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        }); 
        console.log("🚀 ~ file: Products.js:42 ~ storeProducts ~ fetch:", fetch)

        response.status = fetch.status;
        if (fetch.status === CREATED) {
            response.data = fetch.data;
            response.res = true;
        } else if (fetch.status === 422) {
            response.data = fetch.data
        }
        return response;
    } catch (error) {
        return response;
    }

}

export const updateProducts = async ({ headers, data, product_id }) => {
    let response = { res: false, data: {}, status: 500 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${product_id}`,
            method: 'PUT',
            data,
            headers
        });

        console.log("🚀 ~ file: Products.js:68 ~ updateProducts ~ fetch:", fetch)

        response.status = fetch.status;
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
            response.res = true;
        } else if (fetch.status === 400) {
            response.data = fetch.data
        }
        return response;
    } catch (error) {
        return response;
    }
}